// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-todas-categorias-js": () => import("./../../../src/pages/todas-categorias.js" /* webpackChunkName: "component---src-pages-todas-categorias-js" */),
  "component---src-templates-carriers-about-index-js": () => import("./../../../src/templates/carriers/About/index.js" /* webpackChunkName: "component---src-templates-carriers-about-index-js" */),
  "component---src-templates-carriers-main-index-js": () => import("./../../../src/templates/carriers/Main/index.js" /* webpackChunkName: "component---src-templates-carriers-main-index-js" */),
  "component---src-templates-knowledges-categories-index-js": () => import("./../../../src/templates/knowledges/Categories/index.js" /* webpackChunkName: "component---src-templates-knowledges-categories-index-js" */),
  "component---src-templates-knowledges-post-index-js": () => import("./../../../src/templates/knowledges/Post/index.js" /* webpackChunkName: "component---src-templates-knowledges-post-index-js" */)
}

